body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(84, 128, 217);
  color: white;
  overflow: hidden;
}

a:visited {
  color: rgb(65, 65, 65);
}

a {
  color: rgb(65, 65, 65);
}

a:hover {
  text-decoration: none;
}

h1 {
  font-size: 3em;
  text-shadow: -1px 2px #34373e;
}
