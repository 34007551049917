.container {
  position: relative;
  border-bottom: 1px solid #565656;
  height: 180px;
}

.train {
  margin-top: 150px;
  position: absolute;
}

.train img {
  user-select: none;
  height: 30px;
}

.line {
  margin-top: 154px;
  position: absolute;
  right: 50px;
  height: 25px;
  width: 1px;
  border: 1px solid black;
}

.green {
  margin-top: 150px;
  position: absolute;
  right: 46px;
  height: 10px;
  width: 10px;
  border: 1px solid #4dff4d;
  border-radius: 50%;
  cursor: pointer;
}

.red {
  margin-top: 160px;
  position: absolute;
  right: 46px;
  height: 10px;
  width: 10px;
  border: 1px solid red;
  border-radius: 50%;
  cursor: pointer;
}


@media (max-width: 1024px) {
    .container {
        height: 80px;
    }

    .train {
        margin-top: 50px;
    }

    .line {
        margin-top: 54px;
    }

    .green {
        margin-top: 50px;
    }

    .red {
        margin-top: 60px;
    }
}
