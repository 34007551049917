.main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
}

.blocks {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .blocks {
    flex-direction: column;
  }
}

.block {
  color: black;
  max-width: 400px;
  min-height: 100px;
  background-color: white;
  box-shadow: -1px 2px 0px 1px #34373e;
  margin: 10px;
  padding: 10px;
}

.block a {
  font-size: 1.4em;
  padding-right: 8px;
}

.block:hover {
  background-color: rgb(245, 245, 245);
}

.socials {
  margin-top: 60px;
}

.socials > * {
  margin-left: 10px;
  fill: white;
}
